import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import HubspotForm from 'react-hubspot-form'
import { Helmet } from 'react-helmet'

import { Title, Section, Box, Text,  } from '../components/Core'

import PageWrapper from '../components/PageWrapper'

import PageTitle from '../sections/common/PageTitle'

import '../assets/custom.css'


const Contact = () => {
  return (
    <>
    <Helmet>
     <title>Contact Us | Alkemi Network</title>
     <meta name="description" content="Contact Alkemi Network for Alkemi Earn support, Business or learning more about compliant DeFi"/>
     <meta property="og:title" content="Contact Us | Alkemi Network" />
     <meta property="og:description" content="Contact Alkemi Network for Alkemi Earn support, Business or learning more about compliant DeFi" />
     <meta property="og:type" content="website" />
     <meta property="og:locale" content="en_US" />
     <meta property="og:url" content="https://alkemi.network/contact/" />
     <link rel="canonical" href="https://alkemi.network/contact/" />
    </Helmet>
      <PageWrapper footerDark>
        <PageTitle title="Get in touch">
          Send us an email or use the contact form and we will get back to you
          as soon as we can
        </PageTitle>
        <Section>
          <Container>
            <Row>
              <Col lg="6">
              <Box className="contactForm">
                <HubspotForm
                   portalId='1948713'
                   formId='d380d5ad-c564-4511-bad0-ee9f48b1271e'
                   onSubmit={() => console.log('Submit!')}
                   onReady={(form) => console.log('Form ready!')}
                   loading={<div>Loading...</div>}
                   />
                  </Box>
              </Col>
              <Col
                lg={5}
                className="offset-lg-1 order-lg-2 mt-5 mt-lg-0"
              >
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Email Us
                  </Title>
                  <Text variant="small">hello@alkemi.ai</Text>
                </Box>
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Contact Support
                  </Title>
                  <Text variant="small">support@alkemi.ai</Text>
                </Box>
                <Box className="mb-5">
                  <Title variant="card" fontSize="24px">
                    Office Locations
                  </Title>
                  <Text variant="small">60 Atlantic Ave</Text>
                  <Text variant="small">Suite 200</Text>
                  <Text variant="small">Toronto, Ontario, M6K 1X9</Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default Contact
